import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from 'src/app/core/gaurds/auth.gaurd';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavigationPage } from './navigation/navigation.page';
import { NavigationPageRoutingModule } from './navigation/navigation-routing.module';
import { NgPrimeModule } from './app.ngprime.module';
import { AuthInterceptor } from 'src/app/interceptors/auth-interceptor';

@NgModule({
  declarations: [AppComponent, NavigationPage],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NavigationPageRoutingModule,
    HttpClientModule,
    NgPrimeModule
  ],
  providers: [
    AuthGuard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
