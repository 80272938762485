import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { UserContextService } from 'src/app/core/services/user-context.service';

@Component({
  selector: 'app-navigation',
  templateUrl: 'navigation.page.html',
  styleUrls: ['./navigation.page.scss'],
})
export class NavigationPage {
  pageTitle:string = '';
  constructor(private menu: MenuController, private router: Router,
    private activatedRoute: ActivatedRoute, private titleService: Title,
    private userContextService: UserContextService) {}

  ngOnInit() {
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        var rt = this.getChild(this.activatedRoute)
        rt.data.subscribe(data => {
          this.pageTitle = data.title;
          this.titleService.setTitle(data.title)})
      })
  }
 
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  buttonClick(menuItem) {
    this.router.navigate([menuItem]);
    this.menu.toggle('first');
  }

  logOut() {
    this.menu.enable(false, 'first');
    this.userContextService.logout();
    this.router.navigate(['/login']);
  }
}