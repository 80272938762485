import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { LoaderService } from 'src/app/core/services/loader.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private loaderService: LoaderService, private userContextService: UserContextService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loaderService.show();
        // add auth header with jwt if account is logged in and request is to the api url
        var user = this.userContextService.user$.getValue();
        var local:any = null;
        if(localStorage.getItem('userDetails')){
            local = JSON.parse(localStorage.getItem('userDetails'));
        }
        var isLoggedIn = null;
        var isUniqueId = null;
        if (user != null) {
            // logged in so return true
            isLoggedIn = user?.token;
            isUniqueId = user?.unique_id;
        } else if(local) {
            isLoggedIn = local?.token;
            isUniqueId = local?.unique_id;
        }
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isLoggedIn && isApiUrl && isUniqueId) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${isLoggedIn}`, UniqueId: isUniqueId }
            });
        }

        return next.handle(request)
          .pipe(catchError((err) => {
            this.loaderService.hide();
            return throwError(err);
          }))
          .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
            if (evt instanceof HttpResponse) {
              this.loaderService.hide();
            }
            return evt;
        }));
    }
}