import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {delay} from 'rxjs/operators';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {
  showLoader: boolean;
  constructor(private router: Router, private loaderService: LoaderService) {}

  ngOnInit() {
    this.loaderService.status.pipe(delay(0)).subscribe((val: boolean) => {
      this.showLoader = val;
    });

    const currentUser = localStorage.getItem('userDetails');
    if(currentUser){
      this.router.navigate(['/home']);
    }
  }

  ngOnDestroy() {
    this.loaderService.status.observers.forEach(function (element) { element.complete(); });
  }
}
