import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationPage } from './navigation.page';
import { AuthGuard } from 'src/app/core/gaurds/auth.gaurd';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('../home/home.module').then( m => m.HomePageModule),
    data: {title: 'Home'},
    canActivate: [AuthGuard]
  },
  {
    path: 'camera',
    loadChildren: () => import('../camera/camera.module').then( m => m.CameraPageModule),
    data: {title: 'Camera'},
    canActivate: [AuthGuard]
  },
  {
    path: 'week-off',
    loadChildren: () => import('../weekoff/weekoff.module').then( m => m.WeekOffPageModule),
    data: {title: 'Week Off'},
    canActivate: [AuthGuard]
  },
  {
    path: 'attendance',
    loadChildren: () => import('../attendance/attendance.module').then( m => m.AttendancePageModule),
    data: {title: 'Attendance'},
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NavigationPageRoutingModule {}
